<template>
  <div>
  <p class="text-h4">Daily dose of cyrpto</p>
  <v-data-table
    :headers="headers"
    :items="cryptos"
    :items-per-page="5"
    class="elevation-1"
    :loading="coin_loading"
  ></v-data-table>
  </div>
</template>

<script>
export default {
    name: 'Crypto',
    components: {},
    data() {
      return {
        headers:[
          {text: 'Crypto', align: 'start', value:'name', sortable:false},
          {text: 'Value in USD', align: 'start', value:'usd', sortable:false},
        ],
        cryptos:[],
        coin_loading:false
      }
    },
    methods:{
      get_cryptos() {
        // Simple GET request using fetch
        var url = "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum&vs_currencies=usd"
        fetch(url)
          .then(async response => {
            const data = await response.json();
            if (!response.ok) {
              // error
              const error = (data && data.message) || response.statusText;
              this.coin_loading=false;
              return Promise.reject(error);
            }
            //success
            this.coin_loading=false;
            this.cryptos = this.format_data(data);
          })
          .catch(error => {
            this.errorMessage = error;
            this.coin_loading=false;
            console.error("There was an error!", error);
          });
      },
      format_data(data){
        var _coins = []
        for (let key in data){
          var coin_name = key.charAt(0).toUpperCase()+key.slice(1)
          _coins.push({name:coin_name, usd: '$'+data[key].usd})
        }
        return _coins;
      }
    },
    created() {
      // Code is run after the component is created.
      this.coin_loading=true;
      this.get_cryptos();
    }
  }
</script>
